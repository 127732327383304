var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "dataScreen" } }, [
    _vm._m(0),
    _c("div", { staticClass: "data" }, [
      _c("div", { staticClass: "left checked-channel" }, [
        _c("span", [_vm._v(_vm._s(_vm.checkedChannel))])
      ]),
      _c(
        "ul",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.singleLoading,
              expression: "singleLoading"
            }
          ],
          staticClass: "left single",
          attrs: {
            "element-loading-text": "数据加载中，请稍后...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255, 255, 255, 1)"
          }
        },
        _vm._l(_vm.singleChannelData, function(item, index) {
          return _c("li", { key: index, staticClass: "single-item" }, [
            _c("img", {
              staticClass: "icon",
              attrs: { src: require("../icons/" + item.icon) }
            }),
            _c("div", { staticClass: "text" }, [
              _c("p", [
                item.value != null
                  ? _c("span", [_vm._v(_vm._s(item.value))])
                  : _c("span", [_vm._v("0")]),
                _c("span", [_vm._v(_vm._s(item.unit))])
              ]),
              _c("p", [_c("span", [_vm._v(_vm._s(item.label))])])
            ])
          ])
        }),
        0
      ),
      _c("div", { staticClass: "right total" }, [
        _c("div", { staticClass: "lay" }, [
          _vm._m(1),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.totalLoading,
                  expression: "totalLoading"
                }
              ],
              attrs: {
                "element-loading-text": "数据加载中，请稍后...",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255, 255, 255, 1)"
              }
            },
            _vm._l(_vm.totalChannelData, function(item, index) {
              return _c("li", { key: index, staticClass: "total-item" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("../icons/" + item.icon) }
                }),
                _c("div", { staticClass: "text" }, [
                  _c("p", [
                    _c("span", [_vm._v(_vm._s(item.label) + "：")]),
                    item.value != null
                      ? _c("span", [_vm._v(_vm._s(item.value))])
                      : _c("span", [_vm._v("0")]),
                    _c("span", [_vm._v(_vm._s(item.unit))])
                  ])
                ])
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-title" }, [
      _c("span", { staticClass: "title" }, [_vm._v("数据总览")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("总数据（C1+C2+C3）")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }