var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "groupAnalysis" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "search-header" }, [
          _c(
            "div",
            { staticClass: "search-item" },
            [
              _c("label", { staticClass: "large" }, [_vm._v("当前航道：")]),
              _c(
                "el-select",
                {
                  attrs: {
                    "popper-append-to-body": false,
                    size: "small",
                    placeholder: "航道"
                  },
                  on: { change: _vm.handleChangeBuID },
                  model: {
                    value: _vm.checkedBuID,
                    callback: function($$v) {
                      _vm.checkedBuID = $$v
                    },
                    expression: "checkedBuID"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "C1", value: "C1" } }),
                  _c("el-option", { attrs: { label: "C2", value: "C2" } }),
                  _c("el-option", { attrs: { label: "C3", value: "C3" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-item" },
            [
              _c("label", { staticClass: "large" }, [_vm._v("已选城市：")]),
              _c("span", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.checkedRegionsLen))
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "maiaPrimary", size: "small" },
                  on: { click: _vm.configProject }
                },
                [_vm._v("选择城市")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-item" },
            [
              _c("label", { staticClass: "medium" }, [_vm._v("开始时间")]),
              _c("el-date-picker", {
                attrs: {
                  size: "small",
                  "picker-options": _vm.pickerOptions,
                  editable: false,
                  clearable: false,
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "请选择开始时间"
                },
                on: {
                  change: function($event) {
                    return _vm.handleChangeDate(1)
                  }
                },
                model: {
                  value: _vm.startDate,
                  callback: function($$v) {
                    _vm.startDate = $$v
                  },
                  expression: "startDate"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-item" },
            [
              _c("label", { staticClass: "medium" }, [_vm._v("结束时间")]),
              _c("el-date-picker", {
                attrs: {
                  size: "small",
                  "picker-options": _vm.pickerOptions,
                  editable: false,
                  clearable: false,
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "请选择结束时间"
                },
                on: {
                  change: function($event) {
                    return _vm.handleChangeDate(2)
                  }
                },
                model: {
                  value: _vm.endDate,
                  callback: function($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-item" },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  on: { change: _vm.handleRadioClick },
                  model: {
                    value: _vm.dwgOrModel,
                    callback: function($$v) {
                      _vm.dwgOrModel = $$v
                    },
                    expression: "dwgOrModel"
                  }
                },
                _vm._l(_vm.dwgOrModelList, function(item, index) {
                  return _c("el-radio-button", {
                    key: index,
                    attrs: {
                      value: index,
                      disabled: _vm.radioBtnDisabled,
                      label: item
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-item" },
            [
              !_vm.reportingExcelFlag
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "maiaPrimary", size: "small" },
                      on: { click: _vm.exportGroupExcel }
                    },
                    [_vm._v("导出Excel")]
                  )
                : _c(
                    "el-button",
                    { attrs: { type: "info", size: "small", disabled: "" } },
                    [_vm._v("Excel生成中...")]
                  )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "data-screening" },
          [
            _c("data-screen", {
              attrs: {
                totalProjectIds: _vm.totalProjectIds,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeQueryFlag: _vm.changeQueryFlag,
                changeInitFlag: _vm.changeInitFlag,
                changeDateFlag: _vm.changeDateFlag
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("assessor-statistics", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              },
              on: { openIssueStatistical: _vm.openIssueStatistical }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("question-number-statistics", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              },
              on: { openIssueStatistical: _vm.openIssueStatistical }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("question-type-and-major", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("drawing-function-statistics", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("project-drawing-time", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              },
              on: { openIssueStatistical: _vm.openIssueStatistical }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "list-item" },
          [
            _c("prj-insider-or-supplier", {
              attrs: {
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              }
            })
          ],
          1
        )
      ]),
      _vm.dwgOrModelType === 1
        ? _c("issueStatistical", {
            ref: "issueStatistical",
            attrs: { isNewList: false }
          })
        : _vm.dwgOrModelType === 2
        ? _c("issueStatistical3D", {
            ref: "issueStatistical3D",
            attrs: { isNewList: false }
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择列表",
            width: "35%",
            "custom-class": "config-dialog",
            visible: _vm.dialogPrjSettingVisible,
            "before-close": _vm.cancelQuery
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogPrjSettingVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialog-container" }, [
            _c(
              "div",
              { staticClass: "type-list" },
              [
                _c("p", { staticClass: "title" }, [_vm._v("项目类型：")]),
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.handlerChangePrjType },
                    model: {
                      value: _vm.checkedPrjType,
                      callback: function($$v) {
                        _vm.checkedPrjType = $$v
                      },
                      expression: "checkedPrjType"
                    }
                  },
                  _vm._l(_vm.prjTypeList, function(item) {
                    return _c("el-radio-button", {
                      key: item.id,
                      attrs: { label: item.name }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "region-list" },
              [
                _c("p", { staticClass: "title" }, [_vm._v("已选地区：")]),
                _c(
                  "el-checkbox-group",
                  {
                    attrs: { size: "small" },
                    model: {
                      value: _vm.checkedGroupRegions,
                      callback: function($$v) {
                        _vm.checkedGroupRegions = $$v
                      },
                      expression: "checkedGroupRegions"
                    }
                  },
                  [
                    _c(
                      "el-checkbox-button",
                      {
                        staticClass: "checkbox-item",
                        attrs: { label: "全部" },
                        on: {
                          change: function($event) {
                            return _vm.handlerChangeRegion(false)
                          }
                        }
                      },
                      [_vm._v("全部")]
                    ),
                    _vm._l(_vm.indexRegions, function(item) {
                      return _c(
                        "el-checkbox-button",
                        {
                          key: item.id,
                          staticClass: "checkbox-item",
                          attrs: { label: item.id },
                          on: {
                            change: function($event) {
                              return _vm.handlerChangeRegion(item.id)
                            }
                          }
                        },
                        [
                          item != null
                            ? _c("span", [_vm._v(_vm._s(item.name))])
                            : _vm._e()
                        ]
                      )
                    }),
                    _vm.regionList.length > 14
                      ? _c(
                          "el-button",
                          {
                            staticClass: "expand-btn",
                            attrs: {
                              size: "small",
                              type: "primary",
                              plain: ""
                            },
                            on: { click: _vm.toggeleRegionExpand }
                          },
                          [
                            _c("i", {
                              class: [
                                _vm.regionExpandFlag
                                  ? "el-icon-caret-bottom"
                                  : "el-icon-caret-top"
                              ]
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.regionExpandFlag ? "展开" : "收起") +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "project-list" },
              [
                _c(
                  "p",
                  { staticClass: "title" },
                  [
                    _vm._v(" 已选项目 "),
                    _c(
                      "el-select",
                      {
                        staticClass: "search-input",
                        attrs: {
                          multiple: "",
                          "collapse-tags": "",
                          clearable: "",
                          size: "small",
                          filterable: "",
                          remote: "",
                          "reserve-keyword": "",
                          placeholder: "请输入项目名检索",
                          "remote-method": _vm.remoteMethod,
                          loading: _vm.prjLoading
                        },
                        on: { "visible-change": _vm.visibleChange },
                        model: {
                          value: _vm.filterProjectArr,
                          callback: function($$v) {
                            _vm.filterProjectArr = $$v
                          },
                          expression: "filterProjectArr"
                        }
                      },
                      _vm._l(_vm.prjOptions, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "tree-header" }, [
                  _c(
                    "span",
                    [
                      _c("el-checkbox", {
                        staticStyle: { "margin-right": "10px" },
                        on: { change: _vm.toggleCheckPrjAll },
                        model: {
                          value: _vm.checkedAllPrjFlag,
                          callback: function($$v) {
                            _vm.checkedAllPrjFlag = $$v
                          },
                          expression: "checkedAllPrjFlag"
                        }
                      }),
                      _c("label", [_vm._v("区域")])
                    ],
                    1
                  ),
                  _c("span", { staticStyle: { "margin-left": "30px" } }, [
                    _vm._v("项目")
                  ])
                ]),
                _c("el-tree", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeLoading,
                      expression: "treeLoading"
                    }
                  ],
                  ref: "channelTree",
                  staticClass: "channel-tree",
                  attrs: {
                    data: _vm.projectList,
                    props: _vm.treeDataProps,
                    "show-checkbox": "",
                    "node-key": "id",
                    indent: _vm.indentWidth,
                    "render-content": _vm.renderContent,
                    "default-expanded-keys": _vm.expandedKeys,
                    "filter-node-method": _vm.filterNode
                  },
                  on: {
                    "node-click": _vm.handleNodeClick,
                    "check-change": _vm.upper_lowerChange
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.cancelQuery } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.setQuery } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }