var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "assessorStatistics" } }, [
    _c(
      "div",
      { staticClass: "echarts" },
      [
        _c("span", { staticClass: "title" }, [_vm._v("参审人员数量分布统计")]),
        _vm.dwgOrModelType === 1
          ? _c(
              "el-select",
              {
                attrs: {
                  "popper-append-to-body": false,
                  size: "small",
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "全部节点"
                },
                on: { change: _vm.handleChangeFolderID },
                model: {
                  value: _vm.assessorQuery.folderOldIds,
                  callback: function($$v) {
                    _vm.$set(_vm.assessorQuery, "folderOldIds", $$v)
                  },
                  expression: "assessorQuery.folderOldIds"
                }
              },
              [
                _c("el-option", { attrs: { label: "全部节点", value: "" } }),
                _vm._l(_vm.constructPlansData, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.folderName, value: item.rootID }
                  })
                })
              ],
              2
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("label", { staticStyle: { "font-size": "0.8vw" } }, [
              _vm._v("排序方式：")
            ]),
            _c(
              "el-select",
              {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  "popper-append-to-body": false,
                  size: "small",
                  placeholder: "排序方式"
                },
                on: {
                  change: function($event) {
                    return _vm.getAssessorData(2)
                  }
                },
                model: {
                  value: _vm.assessorQuery.sortType,
                  callback: function($$v) {
                    _vm.$set(_vm.assessorQuery, "sortType", $$v)
                  },
                  expression: "assessorQuery.sortType"
                }
              },
              _vm._l(_vm.sortTypeData, function(item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.name, value: item.id }
                })
              }),
              1
            ),
            !_vm.reportingAssessorFlag
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "maiaPrimary", size: "small" },
                    on: { click: _vm.exportAssessorExcel }
                  },
                  [_vm._v("导出Excel")]
                )
              : _c(
                  "el-button",
                  { attrs: { size: "small", type: "info", disabled: "" } },
                  [_vm._v("Excel生成中...")]
                )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "item-chart",
        attrs: {
          "element-loading-text": "数据加载中，请稍后...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 1)"
        }
      },
      [
        _c("div", { staticClass: "total-num" }, [
          _c("span", [
            _vm._v("参审总人数：" + _vm._s(_vm.assessorTotalNum.totalUserNum))
          ]),
          _c("span", [
            _vm._v(
              "供方总人数：" + _vm._s(_vm.assessorTotalNum.totalOutsidersNumber)
            )
          ]),
          _c("span", [
            _vm._v(
              "集团专家总人数：" +
                _vm._s(_vm.assessorTotalNum.totalDrawingExpertNum)
            )
          ]),
          _c("span", [
            _vm._v(
              "项目团队总人数：" + _vm._s(_vm.assessorTotalNum.totalInsiderNum)
            )
          ])
        ]),
        _c("div", { staticClass: "chart", attrs: { id: "assessorChart" } }),
        _vm.projects.length === 0 || _vm.projects == null
          ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }