var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "prjInsiderOrSupplier" } }, [
    _c(
      "div",
      { staticClass: "echarts" },
      [
        _c("span", { staticClass: "title" }, [
          _vm._v("项目人员/分供方人员统计")
        ]),
        _vm.dwgOrModelType === 1
          ? _c(
              "el-select",
              {
                attrs: {
                  "popper-append-to-body": false,
                  size: "small",
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "全部节点"
                },
                on: { change: _vm.handleChangeFolderID },
                model: {
                  value: _vm.prjOrSupplierQuery.folderOldIds,
                  callback: function($$v) {
                    _vm.$set(_vm.prjOrSupplierQuery, "folderOldIds", $$v)
                  },
                  expression: "prjOrSupplierQuery.folderOldIds"
                }
              },
              [
                _c("el-option", { attrs: { label: "全部节点", value: "" } }),
                _vm._l(_vm.constructPlansData, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.folderName, value: item.rootID }
                  })
                })
              ],
              2
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "item-list" }, [
      _c("div", { staticClass: "top-chart" }, [
        _c("div", { staticClass: "title" }, [
          _c("p", [_vm._v("项目人员")]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.prjInsiderData.prjInsiderNum) +
                "人/" +
                _vm._s(_vm.prjInsiderData.prjInsiderRatio) +
                "%"
            )
          ])
        ]),
        _c("div", { staticClass: "chart", attrs: { id: "prjInsiderChart" } })
      ]),
      _c(
        "div",
        { staticClass: "main-table" },
        [
          _c(
            "el-form",
            {
              staticClass: "searchForm",
              attrs: { inline: true, model: _vm.prjInsiderFormData }
            },
            [
              _c(
                "el-form-item",
                { staticClass: "item", attrs: { label: "问题专业" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "popper-append-to-body": false,
                        size: "small",
                        multiple: "",
                        "collapse-tags": "",
                        placeholder: "问题专业"
                      },
                      on: { change: _vm.handleSearchInsiderData },
                      model: {
                        value: _vm.prjInsiderFormData.specIds,
                        callback: function($$v) {
                          _vm.$set(_vm.prjInsiderFormData, "specIds", $$v)
                        },
                        expression: "prjInsiderFormData.specIds"
                      }
                    },
                    _vm._l(_vm.queSpecsData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.specName, value: item.specId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "itemSearch" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请输入姓名",
                        "prefix-icon": "el-icon-search"
                      },
                      model: {
                        value: _vm.prjInsiderFormData.userName,
                        callback: function($$v) {
                          _vm.$set(_vm.prjInsiderFormData, "userName", $$v)
                        },
                        expression: "prjInsiderFormData.userName"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "searchBtn",
                          attrs: {
                            slot: "append",
                            size: "small",
                            type: "maiaPrimary"
                          },
                          on: { click: _vm.handleSearchInsiderData },
                          slot: "append"
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.prjInsiderLoading,
                  expression: "prjInsiderLoading"
                }
              ],
              staticClass: "is-grey",
              staticStyle: { width: "100%" },
              attrs: {
                stripe: "",
                size: "medium",
                height: "300",
                data: _vm.prjInsiderData.prjInsiderList,
                "element-loading-text": "数据加载中，请稍后...",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255, 255, 255, 1)"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var $index = ref.$index
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "tableColor",
                            class: "tableColor" + $index
                          },
                          [_vm._v(_vm._s($index + 1))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "operUserName", label: "姓名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "prjCity", label: "所属地区" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "prjName",
                  label: "所属项目",
                  filters: _vm.filterInsideProjects,
                  "filter-method": _vm.filterHandler,
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "人员归属",
                  prop: "func",
                  filters: _vm.filterInsideFunctions,
                  "filter-method": _vm.filterHandler,
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.functionName))]),
                        !scope.row.iamUserInfoPartyA &&
                        scope.row.organizationList != null
                          ? _c(
                              "span",
                              _vm._l(scope.row.organizationList, function(
                                name,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _c("span", [_vm._v(_vm._s(name.depName))]),
                                  index < scope.row.organizationList.length - 1
                                    ? _c("span", [_vm._v(";")])
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "roleName",
                  label: "角色",
                  filters: _vm.filterInsideRoles,
                  "filter-method": _vm.filterHandler,
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.roleInfoList != null
                          ? _c(
                              "span",
                              _vm._l(scope.row.roleInfoList, function(
                                name,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _c("span", [_vm._v(_vm._s(name.roleName))]),
                                  index < scope.row.roleInfoList.length - 1
                                    ? _c("span", [_vm._v(";")])
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalQuestionNum",
                  label: "总问题数",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validQuestionsNum",
                  label: "有效问题数",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "drawingTime", label: "审图时长", sortable: "" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalQuestionEff",
                  label: "总问题人效",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validQuestionEff",
                  label: "有效问题人效",
                  sortable: ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-handler" },
            [
              _c("el-pagination", {
                attrs: {
                  background: true,
                  "current-page": _vm.prjInsiderFormData.pageNo,
                  "page-sizes": _vm.prjInsiderFormData.pageSizes,
                  "page-size": _vm.prjInsiderFormData.pageSize,
                  "pager-count": _vm.prjInsiderFormData.pagerCount,
                  layout: _vm.pageLayout,
                  total: _vm.prjInsiderFormData.totalCount
                },
                on: {
                  "size-change": _vm.handleSizeChange1,
                  "current-change": _vm.handleCurrentChange1
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "item-list" }, [
      _c("div", { staticClass: "top-chart" }, [
        _c("div", { staticClass: "title" }, [
          _c("p", [_vm._v("分供方人员")]),
          _c("p", [
            _vm._v(
              _vm._s(_vm.prjSupplierData.prjSupplierNum) +
                "人/" +
                _vm._s(_vm.prjSupplierData.prjSupplierRatio) +
                "%"
            )
          ])
        ]),
        _c("div", { staticClass: "chart", attrs: { id: "prjSupplierChart" } })
      ]),
      _c(
        "div",
        { staticClass: "main-table" },
        [
          _c(
            "el-form",
            {
              staticClass: "searchForm",
              attrs: { inline: "", model: _vm.prjSupplierFormData }
            },
            [
              _c(
                "el-form-item",
                { staticClass: "item", attrs: { label: "问题专业" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "popper-append-to-body": false,
                        size: "small",
                        multiple: "",
                        "collapse-tags": "",
                        placeholder: "问题专业"
                      },
                      on: { change: _vm.handleSearchSupplierData },
                      model: {
                        value: _vm.prjSupplierFormData.specIds,
                        callback: function($$v) {
                          _vm.$set(_vm.prjSupplierFormData, "specIds", $$v)
                        },
                        expression: "prjSupplierFormData.specIds"
                      }
                    },
                    _vm._l(_vm.queSpecsData, function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.specName, value: item.specId }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "itemSearch" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        size: "small",
                        placeholder: "请输入姓名",
                        "prefix-icon": "el-icon-search"
                      },
                      model: {
                        value: _vm.prjSupplierFormData.userName,
                        callback: function($$v) {
                          _vm.$set(_vm.prjSupplierFormData, "userName", $$v)
                        },
                        expression: "prjSupplierFormData.userName"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "searchBtn",
                          attrs: {
                            slot: "append",
                            size: "small",
                            type: "maiaPrimary"
                          },
                          on: { click: _vm.handleSearchSupplierData },
                          slot: "append"
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.prjSupplierLoading,
                  expression: "prjSupplierLoading"
                }
              ],
              staticClass: "is-grey",
              staticStyle: { width: "100%" },
              attrs: {
                stripe: "",
                height: "300",
                data: _vm.prjSupplierData.prjSupplierList,
                size: "medium",
                "element-loading-text": "数据加载中，请稍后...",
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255, 255, 255, 1)"
              }
            },
            [
              _c("el-table-column", {
                attrs: { width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var $index = ref.$index
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "tableColor",
                            class: "tableColor" + $index
                          },
                          [_vm._v(_vm._s($index + 1))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "operUserName", label: "姓名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "prjCity", label: "所属地区" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "prjName",
                  label: "所属项目",
                  filters: _vm.filterSupplierProjects,
                  "filter-method": _vm.filterHandler,
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "人员归属",
                  prop: "func",
                  filters: _vm.filterSupplierFunctions,
                  "filter-method": _vm.filterHandler,
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.functionName))]),
                        !scope.row.iamUserInfoPartyA &&
                        scope.row.organizationList != null
                          ? _c(
                              "span",
                              _vm._l(scope.row.organizationList, function(
                                name,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _c("span", [_vm._v(_vm._s(name.depName))]),
                                  index < scope.row.organizationList.length - 1
                                    ? _c("span", [_vm._v(";")])
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "roleName",
                  label: "角色",
                  filters: _vm.filterSupplierRoles,
                  "filter-method": _vm.filterHandler,
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.roleInfoList != null
                          ? _c(
                              "span",
                              _vm._l(scope.row.roleInfoList, function(
                                name,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _c("span", [_vm._v(_vm._s(name.roleName))]),
                                  index < scope.row.roleInfoList.length - 1
                                    ? _c("span", [_vm._v(";")])
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalQuestionNum",
                  label: "总问题数",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validQuestionsNum",
                  label: "有效问题数",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "drawingTime", label: "审图时长", sortable: "" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalQuestionEff",
                  label: "总问题人效",
                  sortable: ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "validQuestionEff",
                  label: "有效问题人效",
                  sortable: ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-handler" },
            [
              _c("el-pagination", {
                attrs: {
                  background: true,
                  "current-page": _vm.prjSupplierFormData.pageNo,
                  "page-sizes": _vm.prjSupplierFormData.pageSizes,
                  "page-size": _vm.prjSupplierFormData.pageSize,
                  "pager-count": _vm.prjSupplierFormData.pagerCount,
                  layout: _vm.pageLayout,
                  total: _vm.prjSupplierFormData.totalCount
                },
                on: {
                  "size-change": _vm.handleSizeChange2,
                  "current-change": _vm.handleCurrentChange2
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }